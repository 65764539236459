<template>
  <nav class="navbar">
    <div class="navbar-container">
      <img src="../assets/logo.png" alt="Online School Logo" class="navbar-logo" />
      <ul class="navbar-links">
        <!-- <li class="language-dropdown">
          <span
            @click="toggleDropdown"
            @mouseover="highlightLanguage = true"
            @mouseleave="highlightLanguage = false"
            :class="{ 'highlighted': highlightLanguage }"
            style="font-size: 15px;"
          >
            {{ $t('languages') }}
          </span>
          <ul v-if="dropdownOpen" class="dropdown-menu">
            <li @click="changeLanguage('ru')" class="dropdown-item">Русский</li>
            <li @click="changeLanguage('en')" class="dropdown-item">English</li>
            <li @click="changeLanguage('kz')" class="dropdown-item">Қазақша</li>
          </ul>
        </li> -->
        <li>
          <a
            href="#"
            @click.prevent="showHelpModal = true"
            class="navbar-link"
          >
            {{ $t('help') }}
          </a>
        </li>
        <li>
          <a
            href="https://forms.gle/ZJV2GugHFDk7LGbt7"
            target="_blank"
            rel="noopener noreferrer"
            class="navbar-link"
          >
            {{ $t('Поступить в школу') }}
          </a>
        </li>
        <!-- <li @click="isLoggedIn ? logout() : login()" class="auth-item auth-link">
          {{ isLoggedIn ? $t('logout') : $t('login') }}
        </li> -->
        <li v-if="isLoggedIn">
          <img :src="user.photoURL" alt="User Avatar" class="user-avatar" />
        </li>
      </ul>
    </div>
    <!-- Модальное окно -->
    <div v-if="showHelpModal" class="modal-overlay" @click.self="closeModal">
      <div class="modal-content">
        <h2>{{ $t('Связаться с нами') }}</h2>
        <p><strong>WhatsApp:</strong> +7 961 981 35 64</p>
        <p><strong>Telegram:</strong> @SkillFoxSchool</p>
        <p><strong>Email:</strong> skillfoxschool@gmail.com</p>
        <button @click="closeModal" class="modal-close">{{ $t('Закрыть') }}</button>
      </div>
    </div>
  </nav>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import router from "@/router/router";

export default {
  name: "NavBarComponent",
  setup() {
    const store = useStore();
    const { locale } = useI18n();
    const dropdownOpen = ref(false);
    const highlightLanguage = ref(false);
    const showHelpModal = ref(false);

    const isLoggedIn = computed(() => !!store.state.auth.user);
    const user = computed(() => store.state.auth.user);

    const login = async () => {
      await store.dispatch('auth/loginWithGoogle', router);
    };

    const logout = async () => {
      await store.dispatch('auth/clearUser');
      await store.dispatch('auth/clearToken');
      router.push('/');
    };

    const changeLanguage = (lang) => {
      locale.value = lang;
      dropdownOpen.value = false;
    };

    const toggleDropdown = () => {
      dropdownOpen.value = !dropdownOpen.value;
    };

    const closeModal = () => {
      showHelpModal.value = false;
    };

    return { 
      changeLanguage, toggleDropdown, dropdownOpen, 
      login, logout, isLoggedIn, user, highlightLanguage, 
      showHelpModal, closeModal 
    };
  },
};
</script>

<style scoped>

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.modal-content h2 {
  margin-bottom: 10px;
}

.modal-content p {
  margin: 5px 0;
}

.modal-close {
  margin-top: 20px;
  padding: 10px 20px;
  background: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.modal-close:hover {
  background: #555;
}
.navbar {
  background-color: #fff; /* Основной цвет для навбара */
  padding: 5px 20px; /* Уменьшенный внутренний отступ */
  border-radius: 5px; /* Закругление углов */
  height: 120px; /* Задайте фиксированную высоту для навбара, если нужно */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Выравнивание по центру */
}

.navbar-logo {
  height: 100px; /* Задайте фиксированную высоту логотипа */
  object-fit: contain; /* Подгонка изображения */
}

.navbar-links {
  list-style-type: none; /* Убираем маркеры у списка */
  margin: 0;
  padding: 0;
  display: flex; /* Горизонтальное расположение элементов */
}

.navbar-links li {
  margin: 0 15px; /* Отступы между пунктами */
  position: relative; /* Необходим для абсолютного позиционирования выпадающего списка */
  display: flex; /* Позволяет использовать flex для вертикального выравнивания */
  align-items: center; /* Центрирует элементы по вертикали */
}

.navbar-links a {
  color: #333; /* Цвет текста для ссылок */
  text-decoration: none; /* Убираем подчеркивание */
  transition: color 0.3s; /* Плавная анимация цвета */
}

.navbar-links a:hover {
  color: #898989; /* Цвет при наведении */
}

.language-dropdown {
  cursor: pointer; /* Указываем, что элемент кликабельный */
}

.highlighted {
  color: #898989; /* Цвет при наведении на текст языка */
  transition: color 0.3s;
}

.dropdown-menu {
  position: absolute; /* Позиционируем выпадающий список */
  background-color: #f2f3f7; /* Цвет фона для выпадающего списка */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Тень для выпадающего списка */
  border-radius: 5px; /* Закругление углов */
  margin-top: 10px; /* Отступ сверху */
  z-index: 1000; /* Чтобы выпадающий список был поверх других элементов */
  list-style-type: none; /* Убираем маркеры у списка языков */
}

.dropdown-menu li {
  padding: 3px; /* Внутренний отступ для пунктов выпадающего списка */
  cursor: pointer; /* Указываем, что элементы кликабельные */
}

.dropdown-menu li:hover {
  background-color: #e0e0e0; /* Цвет фона при наведении на пункт */
}

.user-avatar {
  width: 30px; /* Ширина аватара */
  height: 30px; /* Высота аватара */
  border-radius: 50%; /* Закругляем аватар */
  object-fit: cover; /* Подгонка изображения */
  vertical-align: middle; /* Вертикальное выравнивание */
}

.auth-item {
  color: #000; /* Цвет текста по умолчанию */
  transition: color 0.3s; /* Плавная анимация изменения цвета текста */
}

.auth-item:hover {
  color: #898989; /* Цвет текста при наведении */
  cursor: pointer;
}
</style>
