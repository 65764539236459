<template>
  <NavBarAdmin />
  <NavBarComponent />
  <div class="admin-dashboard">
    <!-- Секция статистики -->
    <section class="admin-stats">
      <h2>{{ $t('statistic') }}</h2>
      <div class="stats-box">
        <div class="stat-item">
          <h3>{{ $t('storage') }}</h3>
          <canvas id="storageChart"></canvas>
          <p>{{ ((serverData.total_storage_gb - serverData.free_storage_gb) / serverData.total_storage_gb * 100).toFixed(2) }}% Used</p>
        </div>
        <div class="stat-item">
          <h3>{{ $t('memory') }}</h3>
          <canvas id="memoryChart"></canvas>
          <p>{{ ((serverData.total_memory_gb - serverData.free_memory_gb) / serverData.total_memory_gb * 100).toFixed(2) }}% Used</p>
        </div>
        <div class="stat-item">
          <h3>{{ $t('cpuUsage') }}</h3>
          <canvas id="cpuChart"></canvas>
          <p>{{ serverData.cpu_usage }}% Used</p>
        </div>
      </div>
    </section>

    <!-- Секция информации об аккаунтах -->
    <section class="admin-accounts">
      <h2>{{ $t('userAccounts') }}</h2>
      <table class="accounts-table">
        <thead>
          <tr>
            <th>{{ $t('name') }}</th>
            <th>{{ $t('email') }}</th>
            <th>{{ $t('uid') }}</th>
            <th>{{ $t('registrationDate') }}</th>
            <th>{{ $t('roles') }}</th>
            <th>{{ $t('actions') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="account in accounts" :key="account.id">
            <td>{{ account.name }}</td>
            <td>{{ account.email }}</td>
            <td>{{ account.uid }}</td>
            <td>{{ new Date(account.registration_date).toLocaleDateString() }}</td>
            <td>{{ account.roles.join(', ') }}</td>
            <td>
              <button @click="editAccount(account)">{{ $t('edit') }}</button>
            </td>
          </tr>
        </tbody>
      </table>
    </section>

    <!-- Секция расписания -->
    <section class="admin-schedule-tabs">
      <h2>{{ $t('weeklySchedule') }}</h2>
      <div class="tabs">
        <button
          v-for="day in weekDays"
          :key="day"
          :class="{ active: selectedDay === day }"
          @click="selectedDay = day"
        >
          {{ day }}
        </button>
      </div>
      <div class="schedule-content">
        <ul v-if="schedule[selectedDay]">
          <li v-for="event in schedule[selectedDay]" :key="event.id">
            <h4>{{ event.time }} - {{ event.title }}</h4>
            <p>{{ $t('instructor') }}: {{ event.instructor }}</p>
          </li>
        </ul>
        <p v-else>{{ $t('noEvents') }}</p>
      </div>
    </section>

<!-- Секция ввода запроса -->
<section class="admin-query">
  <h2>{{ $t('querySection') }}</h2>
  <div class="query-input-container">
    <input type="text" v-model="query" placeholder="Введите ваш запрос" />
    <button @click="submitQuery">{{ $t('submit') }}</button>
  </div>
  <pre v-if="queryResult" class="query-result">{{ queryResult }}</pre>
</section>
  </div>
</template>

<script>
import NavBarComponent from '@/components/NavBarComponent.vue';
import NavBarAdmin from '@/components/NavBarAdmin.vue';
import { Chart, registerables } from 'chart.js';
import apiClient from '@/api/axios';

export default {
  name: "AdminPanel",
  components: {
    NavBarComponent,
    NavBarAdmin,
  },
  data() {
    return {
      accounts: [],
      weekDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      selectedDay: 'Monday',
      schedule: {
        Monday: [
          { id: 1, time: '10:00 AM', title: 'Web Development - Lesson 1', instructor: 'John Doe' },
          { id: 2, time: '12:00 PM', title: 'Data Science - Lesson 1', instructor: 'Jane Smith' },
        ],
        Tuesday: [
          { id: 3, time: '10:00 AM', title: 'Web Development - Lesson 2', instructor: 'John Doe' },
        ],
      },
      serverData: {
        total_storage_gb: 0,
        free_storage_gb: 0,
        total_memory_gb: 0,
        free_memory_gb: 0,
        cpu_usage: 0,
      },
      query: '', // Поле для запроса
      queryResult: null // Поле для хранения результата запроса
    };
  },
  methods: {
    async fetchServerData() {
      try {
        const response = await apiClient.get('/get_server_info');
        this.serverData = response.data;
        this.renderCharts();
      } catch (error) {
        console.error("Error fetching server data:", error);
      }
    },
    async fetchUserAccounts() {
      try {
        const response = await apiClient.get('/users');
        this.accounts = response.data;
      } catch (error) {
        console.error("Error fetching user accounts:", error);
      }
    },
    async submitQuery() {
      try {
        const response = await apiClient.post('/execute_query', { query: this.query });
        this.queryResult = JSON.stringify(response.data, null, 2); // Форматируем JSON-ответ
      } catch (error) {
        console.error("Error submitting query:", error);
      }
    },
    renderCharts() {
      Chart.register(...registerables);
      new Chart(document.getElementById('storageChart'), {
        type: 'doughnut',
        data: {
          datasets: [{
            data: [
              this.serverData.free_storage_gb, 
              this.serverData.total_storage_gb - this.serverData.free_storage_gb
            ],
            backgroundColor: ['#4542f5', '#ff0303'],
          }]
        }
      });

      new Chart(document.getElementById('memoryChart'), {
        type: 'doughnut',
        data: {
          datasets: [{
            data: [
              this.serverData.free_memory_gb, 
              this.serverData.total_memory_gb - this.serverData.free_memory_gb
            ],
            backgroundColor: ['#4542f5', '#ff0303'],
          }]
        }
      });

      new Chart(document.getElementById('cpuChart'), {
        type: 'doughnut',
        data: {
          datasets: [{
            data: [
              100 - this.serverData.cpu_usage, 
              this.serverData.cpu_usage
            ],
            backgroundColor: ['#4542f5', '#ff0303'],
          }]
        }
      });
    },
    editAccount(account) {
      alert(`Editing account: ${account.username}`);
    }
  },
  mounted() {
    this.fetchServerData();
    this.fetchUserAccounts();
  }
};
</script>

<style scoped>
 /* Основной контейнер для панели администратора */
 .admin-dashboard {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 20px;
    background-color: #f9fafc;
    background-color: #ff0303;
  }
  
  /* Секция статистики */
  .admin-stats, .admin-accounts, .admin-schedule-tabs {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Таблицы аккаунтов */
  .accounts-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .accounts-table th, .accounts-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .accounts-table th {
    background-color: #f9fafc;
    color: #2d3748;
    font-weight: bold;
  }
  
  .accounts-table td button {
    background-color: #3182ce;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .accounts-table td button:hover {
    background-color: #2b6cb0;
  }
  
  /* Вкладки с днями недели */
  .admin-schedule-tabs .tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .admin-schedule-tabs .tabs button {
    background-color: #f7fafc;
    border: 1px solid #e2e8f0;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .admin-schedule-tabs .tabs button.active {
    background-color: #3182ce;
    color: #fff;
  }
  
  .admin-schedule-tabs .tabs button:hover {
    background-color: #2b6cb0;
    color: #fff;
  }
  
  /* Контент расписания */
  .admin-schedule-tabs .schedule-content ul {
    list-style: none;
    padding: 0;
  }
  
  .admin-schedule-tabs .schedule-content ul li {
    margin-bottom: 15px;
  }
  
  .admin-schedule-tabs .schedule-content h4 {
    font-size: 18px;
    color: #2d3748;
  }
  
  .admin-schedule-tabs .schedule-content p {
    font-size: 14px;
    color: #718096;
  }
/* Основной контейнер для панели администратора */
.admin-dashboard {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
  background-color: #f9fafc;
}

/* Секция статистики */
.admin-stats {
  display: flex;
  justify-content: space-between;
}

.stats-box {
  display: flex;
  gap: 40px;
}

.stat-item {
  flex: 1;
  text-align: center;
}

/* Для канвасов диаграмм, чтобы они не занимали слишком много места */
canvas {
  width: 200px; /* Фиксированная ширина */
  height: 200px; /* Фиксированная высота */
  margin: 0 auto;
}
/* Секция ввода запроса */
.admin-query {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Контейнер для поля ввода и кнопки */
.query-input-container {
  display: flex;
  gap: 10px; /* Расстояние между полем ввода и кнопкой */
}

/* Стиль для поля ввода */
.admin-query input {
  flex: 1; /* Занимает оставшееся пространство */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Тень для поля ввода */
  transition: border-color 0.3s ease;
}

.admin-query input:focus {
  border-color: #3182ce; /* Цвет рамки при фокусе */
  outline: none; /* Убираем стандартный контур */
}

/* Стиль для кнопки */
.admin-query button {
  background-color: #3182ce;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px; /* Увеличиваем размеры кнопки */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Добавляем эффект плавного перехода */
}

.admin-query button:hover {
  background-color: #2b6cb0;
  transform: translateY(-2px); /* Эффект при наведении */
}

/* Стиль для результата запроса */
.admin-query pre {
  background-color: #f7fafc;
  padding: 15px;
  border-radius: 5px;
  overflow-x: auto;
  margin-top: 10px; /* Отступ сверху */
  border: 1px solid #e2e8f0; /* Рамка вокруг результата */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Тень для результата */
}
.edu-navbar {
  background-color: #4a5568; /* Соответствие стилистике предыдущей страницы */
  padding: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.edu-navbar ul {
  display: flex;
  justify-content: space-around;
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
