<template>
    <NavBarAdmin />
    <div class="admin-dashboard">
      <!-- Секция для управления предметами -->
      <section class="admin-subjects">
        <h2>Управление предметами</h2>
        
        <!-- Форма для создания/редактирования предмета -->
        <div class="subject-form">
          <input v-model="subject.title" placeholder="Название предмета" />
          <input v-model="subject.time" placeholder="Время" />
          <button @click="saveSubject">{{ isEditing ? 'Редактировать' : 'Создать' }}</button>
        </div>
        
        <!-- Таблица со списком предметов -->
        <table class="subjects-table">
          <thead>
            <tr>
              <th>Название</th>
              <th>Время</th>
              <th>Действия</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="sub in subjects" :key="sub.id">
              <td>{{ sub.title }}</td>
              <td>{{ sub.time }}</td>
              <td>
                <button @click="editSubject(sub)">Редактировать</button>
                <button @click="deleteSubject(sub.id)">Удалить</button>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      
      <!-- Секция для назначения пользователей -->
        <section class="admin-user-subjects">
        <h2>Назначение пользователей к предметам</h2>
        <div class="user-form">
            <input v-model="userId" placeholder="ID пользователя" />
            <select v-model="selectedSubject">
            <option v-for="sub in subjects" :key="sub.id" :value="sub.id">{{ sub.title }}</option>
            </select>
            <label>
            <input type="checkbox" v-model="canRead" /> Чтение
            </label>
            <label>
            <input type="checkbox" v-model="canEdit" /> Редактирование
            </label>
            <button @click="assignUserToSubject">Назначить</button>
        </div>
        </section>
    </div>
  </template>
<script>
import NavBarAdmin from '@/components/NavBarAdmin.vue';
import apiClient from '@/api/axios';

export default {
  name: 'SubjectManager',
  components: { NavBarAdmin },
  data() {
    return {
      subjects: [],  // Список предметов
      subject: { title: '', time: '' }, // Текущий предмет
      isEditing: false, // Флаг редактирования
      userId: '',  // ID пользователя для назначения
      selectedSubject: null, // Выбранный предмет
      canRead: false,
      canEdit: false,
    };
  },
  methods: {
    async fetchSubjects() {
      try {
        const response = await apiClient.get('/get_subjects');
        this.subjects = response.data;
      } catch (error) {
        console.error("Ошибка при загрузке предметов:", error);
      }
    },
    async saveSubject() {
      if (this.isEditing) {
        await this.updateSubject();
      } else {
        await this.createSubject();
      }
    },
    async createSubject() {
      try {
        const response = await apiClient.post('/create_subjects', this.subject);
        this.subjects.push(response.data);
        this.resetForm();
      } catch (error) {
        console.error("Ошибка при создании предмета:", error);
      }
    },
    async updateSubject() {
      try {
        await apiClient.put(`/update_subjects/${this.subject.id}`, this.subject);
        this.fetchSubjects();
        this.resetForm();
      } catch (error) {
        console.error("Ошибка при обновлении предмета:", error);
      }
    },
    editSubject(sub) {
      this.subject = { ...sub };
      this.isEditing = true;
    },
    async deleteSubject(id) {
      try {
        await apiClient.delete(`/delete_subjects/${id}`);
        this.fetchSubjects();
      } catch (error) {
        console.error("Ошибка при удалении предмета:", error);
      }
    },
    async assignUserToSubject() {
      try {
        await apiClient.post('/user_subjects/', {
          user_id: this.userId,
          subject_id: this.selectedSubject,
          can_read: this.canRead,
          can_edit: this.canEdit
        });
      } catch (error) {
        console.error("Ошибка при назначении пользователя:", error);
      }
    },
    resetForm() {
      this.subject = { title: '', time: '' };
      this.isEditing = false;
    }
  },
  mounted() {
    this.fetchSubjects();
  }
};
</script>
<style scoped>
.admin-subjects,
.admin-user-subjects {
  background-color: #f9f9f9; /* Цвет фона */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; /* Отступ между секциями */
}

h2 {
  color: #333; /* Цвет заголовка */
  margin-bottom: 20px;
}

.subject-form,
.user-form {
  display: flex; /* Гибкое отображение для формы */
  gap: 10px; /* Отступ между элементами */
  margin-bottom: 20px; /* Отступ снизу */
}

.subject-form input,
.user-form input,
.user-form select {
  padding: 10px;
  border: 1px solid #ccc; /* Цвет рамки */
  border-radius: 5px;
  width: 200px; /* Ширина полей ввода */
  transition: border-color 0.3s; /* Плавный переход */
}

.subject-form input:focus,
.user-form input:focus,
.user-form select:focus {
  border-color: #3182ce; /* Цвет рамки при фокусе */
}

.subject-form button,
.user-form button {
  background-color: #3182ce; /* Цвет кнопок */
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s; /* Плавный переход */
}

.subject-form button:hover,
.user-form button:hover {
  background-color: #2b6cb0; /* Цвет кнопки при наведении */
}

.subjects-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.subjects-table th,
.subjects-table td {
  padding: 12px;
  border-bottom: 1px solid #ccc; /* Цвет рамки таблицы */
  text-align: left; /* Выравнивание текста */
}

.subjects-table th {
  background-color: #e2e8f0; /* Цвет фона заголовка таблицы */
  color: #333; /* Цвет текста заголовка */
}

.subjects-table td button {
  background-color: #3182ce; /* Цвет кнопки в таблице */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  transition: background-color 0.3s; /* Плавный переход */
}

.subjects-table td button:hover {
  background-color: #2b6cb0; /* Цвет кнопки при наведении */
}

</style>
