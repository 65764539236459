<template>
    <div class="not-found">
      <h1>{{ $t('pageNotFound') }}</h1>
      <p>{{ $t('pageNotFoundDescription') }}</p>
      <router-link to="/" class="go-home-button">{{ $t('goHome') }}</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: "NotFoundPage",
  };
  </script>
  
  <style scoped>
  /* Стили для страницы 404 */
  .not-found {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f9fafc; /* Соответствует фону предыдущих страниц */
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.not-found h1 {
  font-size: 3em;
  color: #2d3748; /* Соответствие цвету заголовков на предыдущих страницах */
  margin-bottom: 20px;
}

.not-found p {
  font-size: 1.5em;
  color: #4a5568; /* Соответствует оттенкам текста на предыдущих страницах */
  margin-bottom: 40px;
}

.go-home-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: #4a5568; /* Соответствует основному цвету кнопок */
  color: white;
  text-decoration: none;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.go-home-button:hover {
  background-color: #2c3e50; /* Легкая затемненность при наведении для более выразительного эффекта */
  transform: translateY(-4px);
}

  </style>
  