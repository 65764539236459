import { createStore } from 'vuex';
import authStore from './store/AuthStore';

const store = createStore({
  modules: {
    auth: authStore,
  },
});

export default store;
