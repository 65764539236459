<template>
<div class="homepage">  
            <!-- Яркий блок акции -->
            <section class="promotion">
          <p class="promotion-text">
  🔥 Акция! ЛЮБОЕ занятие всего за <span class="price">1990₸</span>! 🏃‍♂️ Спешите записаться!
</p>
    </section>
    <NavBarComponent />
    <header>
      <h1 class="title">SkillFoxSchool</h1>
      <p class="description">
        Добро пожаловать в SkillFoxSchool — обучайтесь новым навыкам легко и эффективно!
      </p>
    </header>

    <section class="courses">
      <h2>Наши курсы</h2>
      <div class="courses-container">
        <button class="scroll-button left" @click="scrollLeft">‹</button>
        <div class="courses-list" ref="coursesList">
          <div v-for="course in courses" :key="course.id" class="course-item" @click="openInstagram">
        <img :src="course.image" :alt="course.title" class="course-image" />
        <div class="course-details">
          <h3 class="course-title">{{ course.title }}</h3>
          <p class="course-description">{{ course.description }}</p>
        </div>
      </div>
    </div>
    <button class="scroll-button right" @click="scrollRight">›</button>
  </div>
</section>
  </div>
</template>
<script>
import NavBarComponent from '@/components/NavBarComponent.vue';

export default {
  name: "HomePage",
  components: {
    NavBarComponent,
  },
  data() {
    return {
      courses: [
  {
    id: 2,
    title: "Программирование на Python",
    description: "Данный курс научит вас с нуля разрабатывать консольные приложения на языке Python. Вы изучите основные концепции программирования, работу с данными, функции и модули, а также получите практические навыки для создания собственных проектов.",
    image: require('@/assets/python.webp'), // Замените на URL изображения
  },
  {
    id: 3,
    title: "Анализ данных на Python",
    description: "Овладейте инструментами анализа данных с Python, включая работу с библиотеками Pandas, NumPy и визуализацию данных с помощью Matplotlib и Seaborn. Научитесь извлекать полезные инсайты из данных и строить аналитические отчеты.",
    image: require('@/assets/data.webp'), // Замените на URL изображения
  },
  {
    id: 4,
    title: "Веб-программирование",
    description: "Освойте основные технологии веб-разработки, включая HTML, CSS, JavaScript, а также работу с фреймворками и библиотеками для создания современных веб-приложений. Научитесь разрабатывать функциональные веб-сайты и интерфейсы для пользователей.",
    image: require('@/assets/web.webp'), // Замените на URL изображения
  },
  {
    id: 1,
    title: "Основы Blender",
    description: "Применяя знания и навыки, полученные в ходе курса, вы сможете создавать простые 3D-сцены в Blender. К концу курса у вас будет готово изображение, демонстрирующее освоенные техники и возможности Blender.",
    image: require('@/assets/blender.png'), // Замените на URL изображения
  },
],
    };
  },
  methods: {
    scrollLeft() {
      const list = this.$refs.coursesList;
      list.scrollBy({ left: -list.clientWidth, behavior: "smooth" });
    },
    scrollRight() {
      const list = this.$refs.coursesList;
      list.scrollBy({ left: list.clientWidth, behavior: "smooth" });
    },
    openInstagram() {
      window.open(
        "https://www.instagram.com/skillfoxschool/profilecard/?igsh=MWN5bWtrM3d2b2FzZg==",
        "_blank"
      );
    },
  },
};
</script>

<style scoped>
.homepage {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.description {
  font-size: 1.2rem;
  color: #666;
  text-align: center;
  margin-bottom: 30px;
}

.courses {
  margin-top: 30px;
}

.courses-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.courses-list {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  gap: 10px;
  padding: 10px 0;
  width: 100%;
}

.course-item {
  flex: 0 0 auto;
  width: 90%;
  max-width: 300px;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background: #fff;
}

.course-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.course-details {
  padding: 15px;
}

.course-title {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.course-description {
  font-size: 0.9rem;
  color: #555;
  text-align: center;
}

.scroll-button {
  display: none; /* Отключаем кнопки прокрутки на телефоне */
}

@media (min-width: 768px) {
  .courses-container {
    flex-direction: row;
  }

  .scroll-button {
    display: block;
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 2rem;
    cursor: pointer;
    width: 50px;
    height: 50px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }
  .courses-list {
  display: flex;
  overflow: hidden; /* Убирает прокрутку */
  scroll-behavior: smooth;
  gap: 10px;
  padding: 10px 0;
  width: 100%;
}

  .scroll-button.left {
    left: -25px;
  }

  .scroll-button.right {
    right: -25px;
  }

  .scroll-button:hover {
    background: rgba(0, 0, 0, 0.8);
  }

  .course-item {
    width: 550px;
    max-width: none;
  }

  .course-image {
    height: 400px;
  }
}
.promotion {
  position: fixed; /* Закрепляем блок */
  top: 0; /* Прижимаем к верхнему краю */
  left: 0; /* Располагаем слева */
  width: 100%; /* Растягиваем на всю ширину экрана */
  background: linear-gradient(90deg, #ff8a00, #ff0000);
  color: white;
  padding: 20px;
  text-align: center;
  z-index: 1000; /* Повышаем уровень слоя, чтобы он оказался над другими элементами */
  border-radius: 0; /* Убираем закругления, чтобы он выглядел как полноценная полоса */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  font-size: 1.5rem;
}

.promotion-text {
  margin: 0;
}

.price {
  font-size: 2rem;
  font-weight: bold;
  color: #ffff00;
}
@media (max-width: 768px) {
  .promotion {
    font-size: 1rem; /* Уменьшаем шрифт */
    padding: 10px; /* Уменьшаем отступы */
  }

  .promotion-text {
    font-size: 0.9rem; /* Еще меньше текст */
  }

  .price {
    font-size: 1.5rem; /* Уменьшаем размер цены */
  }
}

</style>
