import { createI18n } from 'vue-i18n';

const messages = {
  en: {
    welcome: 'Welcome to the Online School!',
    learnSkills: 'Learn new skills and achieve your goals with our courses.',
    onlineUsers: 'Online Users',
    taskBoard: 'Current Tasks',
    subject: 'Subject',
    deadline: 'Time Remaining:',
    announcements: 'Announcements',
    instructions: 'Instructions',
    yourCourses: 'Your Courses',
    gradeBook: 'Grade Book',
    yourFiles: 'Your Files',
    schedule: 'Schedule',
    student: {
      title: 'Are you a student?',
      description: 'Discover new horizons with our programming courses!',
    },
    teacher: {
      title: 'Are you a teacher?',
      description: 'Join our team and get the opportunity to work remotely with a flexible schedule and a decent salary.',
    },
    home: 'Home',
    school_name: 'Online School',
    languages: 'ENG',
    login: 'Login',
    logout: 'Logout',
    help: 'Help',
    weeklySchedule: 'Weekly Schedule',
    time: 'Time',
    tasks: 'Tasks',
    title: 'Title',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    myCourses: 'My Courses',
    instructor: 'Instructor',
    progress: 'Progress',
    description: 'Description',
    duration: 'Duration',
    enroll: 'Enroll',
    pageNotFound: 'Page Not Found',
    pageNotFoundDescription: 'Sorry, the page you are looking for could not be found.',
    goHome: 'Go Home'
  },
  ru: {
    welcome: 'Добро пожаловать в Онлайн Школу!',
    learnSkills: 'Изучайте новые навыки и достигайте своих целей с нашими курсами.',
    onlineUsers: 'Пользователи онлайн',
    taskBoard: 'Текущие задания',
    subject: 'Предмет',
    deadline: 'Осталось до завершения:',
    announcements: 'Объявления',
    instructions: 'Инструкции',
    yourCourses: 'Ваши курсы',
    gradeBook: 'Журнал оценок',
    yourFiles: 'Ваши файлы',
    schedule: 'Расписание',
    student: {
      title: 'Вы ученик?',
      description: 'Откройте для себя новые горизонты с нашими курсами по программированию!',
    },
    teacher: {
      title: 'Вы преподаватель?',
      description: 'Присоединяйтесь к нашей команде и получите возможность работать удаленно с гибким графиком и достойным окладом.',
    },
    home: 'Главная',
    school_name: 'Онлайн Школа',
    languages: 'RU',
    login: 'Вход',
    logout: 'Выход',
    help: 'Помощь',
    weeklySchedule: 'Расписание на неделю',
    time: 'Время',
    tasks: 'Задачи',
    title: 'Заголовок',
    monday: 'Понедельник',
    tuesday: 'Вторник',
    wednesday: 'Среда',
    thursday: 'Четверг',
    friday: 'Пятница',
    saturday: 'Суббота',
    sunday: 'Воскресенье',
    myCourses: 'Мои курсы',
    instructor: 'Инструктор',
    progress: 'Прогресс',
    description: 'Описание',
    duration: 'Продолжительность',
    enroll: 'Записаться',
    pageNotFound: 'Страница не найдена',
    pageNotFoundDescription: 'Извините, но запрашиваемая вами страница не найдена.',
    goHome: 'На главную'
  },
  kz: {
    welcome: 'Онлайн мектепке қош келдіңіз!',
    learnSkills: 'Біздің курстарымызбен жаңа дағдыларды үйреніп, мақсаттарыңызға жетіңіз.',
    onlineUsers: 'Онлайн пайдаланушылар',
    taskBoard: 'Ағымдағы тапсырмалар',
    subject: 'Пән',
    deadline: 'Даяр болу уақыты:',
    announcements: 'Хабарламалар',
    instructions: 'Нұсқаулар',
    yourCourses: 'Сіздің курстарыңыз',
    gradeBook: 'Бағалау журналы',
    yourFiles: 'Сіздің файлдарыңыз',
    schedule: 'Кесте',
    student: {
      title: 'Сіз студентсіз бе?',
      description: 'Бағдарламалау курстарымен жаңа көкжиектерді ашыңыз!',
    },
    teacher: {
      title: 'Сіз мұғалімсіз бе?',
      description: 'Біздің командаға қосылыңыз және қашықтан жұмыс істеу, икемді жұмыс кестесі мен лайықты жалақы алуға мүмкіндік алыңыз.',
    },
    home: 'Басты',
    school_name: 'Онлайн Мектеп',
    languages: 'KZ',
    login: 'Кіру',
    logout: 'Шығу',
    help: 'Көмек',
    weeklySchedule: 'Апта бойы кесте',
    time: 'Уақыт',
    tasks: 'Тапсырмалар',
    title: 'Атауы',
    monday: 'Дүйсенбі',
    tuesday: 'Сейсенбі',
    wednesday: 'Сәрсенбі',
    thursday: 'Бейсенбі',
    friday: 'Жұма',
    saturday: 'Сенбі',
    sunday: 'Жексенбі',
    myCourses: 'Менің курстарым',
    instructor: 'Инструктор',
    progress: 'Прогресс',
    description: 'Сипаттама',
    duration: 'Ұзақтығы',
    enroll: 'Тіркелу',
    pageNotFound: 'Бет табылмады',
    pageNotFoundDescription: 'Кешіріңіз, іздеген бетіңіз табылмады.',
    goHome: 'Басты бетке'
  },
};

const i18n = createI18n({
  locale: 'ru', // Установите язык по умолчанию
  messages,
});

export default i18n;
