<template>
    <NavBarComponent />
    <NavBarEdu />
    <div class="dashboard">
      <!-- Левая часть: список пользователей онлайн -->
      <aside class="online-list">
        <h2>{{ $t('onlineUsers') }}</h2>
        <ul>
          <li v-for="user in onlineUsers" :key="user.id" class="user-item">
            {{ user.name }}
          </li>
        </ul>
      </aside>
  
      <!-- Правая часть: доска с заданиями -->
      <section class="task-board">
        <h2>{{ $t('taskBoard') }}</h2>
        <div v-for="task in tasks" :key="task.id" class="task-item">
          <h3>{{ task.title }}</h3>
          <p>{{ $t('subject') }}: {{ task.subject }}</p>
          <p>{{ $t('deadline') }}: {{ task.deadline }}</p>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  import NavBarComponent from '@/components/NavBarComponent.vue';
  import NavBarEdu from '@/components/NavBarEdu.vue';
  export default {
    name: "TaskDashboard",
    components: {
      NavBarComponent,
      NavBarEdu,
    },
    data() {
      return {
        onlineUsers: [
          { id: 1, name: 'Alice' },
          { id: 2, name: 'Bob' },
          { id: 3, name: 'Charlie' },
        ],
        tasks: [
          { id: 1, title: 'Math Homework', subject: 'Mathematics', deadline: '2 days' },
          { id: 2, title: 'Science Project', subject: 'Science', deadline: '5 days' },
          { id: 3, title: 'Essay on History', subject: 'History', deadline: '1 week' },
        ],
      };
    },
  };
  </script>
  
  <style scoped>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* Навбар */
.edu-navbar {
  background-color: #4a5568; /* Соответствие стилистике предыдущей страницы */
  padding: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.edu-navbar ul {
  display: flex;
  justify-content: space-around;
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Главное разделение страницы с новым фоном */
.dashboard {
  display: flex;
  height: 100vh;
  background-color: #f9fafc; /* Сохранение общей гаммы */
}

/* Стили для списка пользователей онлайн */
.online-list {
  width: 25%;
  background-color: #fff; /* Изменено на белый, чтобы соответствовать панели курсов */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Добавлена тень для стилистической целостности */
}

.online-list h2 {
  color: #2d3748; /* Цвет заголовков как на первой странице */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Стили для элементов списка пользователей */
.user-item {
  background-color: #f4f7fa; /* Совпадает с курсами */
  border: 1px solid #d9e2ec; /* Одинаковый стиль с элементами курса */
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease-in-out, background-color 0.3s ease;
}

.user-item:hover {
  background-color: #e6ecf3;
  transform: translateY(-4px);
}

/* Стили для доски с заданиями */
.task-board {
  flex-grow: 1;
  padding: 30px;
  background-color: #fff; /* Соответствует правой части страницы курсов */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.task-board h2 {
  margin-bottom: 25px;
  color: #2d3748;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Стили для карточек заданий */
.task-item {
  background-color: #ffffff;
  border: 1px solid #e2e8f0; /* Объединение с рамками у уроков */
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Поддержка визуальной консистентности */
  transition: box-shadow 0.3s ease;
}

.task-item:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.task-item h3 {
  color: #2d3748;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 15px;
}

.task-item p {
  color: #4a5568;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6;
}

  </style>
  