<template>
  <NavBarComponent />
  <NavBarEdu />
  <div class="dashboard">
    <!-- Правая часть: расписание на неделю в виде таблицы -->
    <section class="schedule-board">
      <h2>{{ $t('weeklySchedule') }}</h2>
      <table class="schedule-table">
        <thead>
          <tr>
            <th>{{ $t('time') }}</th>
            <th v-for="day in weekDays" :key="day.name">{{ $t(day.name) }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="timeSlot in timeSlots" :key="timeSlot">
            <td>{{ timeSlot }}</td>
            <td v-for="day in weekDays" :key="day.name">
              <div v-if="day.tasks">
                <div v-for="task in getTasksForTimeSlot(day, timeSlot)" :key="task.id">
                  {{ task.title }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</template>

<script>
import NavBarComponent from '@/components/NavBarComponent.vue';
import NavBarEdu from '@/components/NavBarEdu.vue';
import apiClient from '@/api/axios';

export default {
  name: "SchedulePage",
  components: {
    NavBarComponent,
    NavBarEdu,
  },
  data() {
    return {
      weekDays: [
        { name: 'monday', tasks: [] },
        { name: 'tuesday', tasks: [] },
        { name: 'wednesday', tasks: [] },
        { name: 'thursday', tasks: [] },
        { name: 'friday', tasks: [] },
        { name: 'saturday', tasks: [] },
        { name: 'sunday', tasks: [] },
      ],
      timeSlots: ['9:00 - 10:30', '10:30 - 12:00', '12:00 - 13:30', '13:30 - 15:00', '15:00 - 16:30'],
    };
  },
  methods: {
    getTasksForTimeSlot(day, timeSlot) {
      return day.tasks ? day.tasks.filter(task => task.time === timeSlot) : [];
    },
    async fetchData() {
      try {
        const response = await apiClient.get('/schedule'); // Убедитесь, что этот эндпоинт правильный
        this.weekDays = response.data;
        console.log(response.data);
      } catch (error) {
        console.error('Ошибка при запросе:', error);
      }
    }
  },
  mounted() {
    this.fetchData(); // Запрашиваем данные при монтировании компонента
  },
};
</script>
<style scoped>
.edu-navbar {
  background-color: #4a5568; /* Изменено на темный цвет для соответствия стилю первой страницы */
  padding: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.edu-navbar ul {
  display: flex;
  justify-content: space-around;
  list-style: none;
  margin: 0;
  padding: 0;
}

.dashboard {
  display: flex;
  height: calc(100vh - 60px); /* Учитываем высоту фиксированного навбара */
  margin-top: 0;
  background-color: #f9fafc; /* Соответствие фоновому цвету предыдущей страницы */
}

/* Стили для расписания */
.schedule-board {
  flex-grow: 1;
  padding: 30px;
  background-color: #ffffff; /* Соответствие стилю правой части предыдущей страницы */
  margin-top: 0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Единая стилистика для контента */
}

.schedule-board h2 {
  margin-bottom: 25px;
  color: #2d3748; /* Соответствует заголовкам на предыдущей странице */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Таблица расписания */
.schedule-table {
  width: 100%;
  border-collapse: collapse;
}

.schedule-table th,
.schedule-table td {
  border: 1px solid #e2e8f0; /* Обновлено для единообразия с границами на предыдущей странице */
  padding: 12px;
  text-align: left;
  background-color: #ffffff;
}

.schedule-table th {
  background-color: #edf2f7; /* Легкий светлый фон для заголовков таблицы */
  color: #2d3748;
}

.schedule-table tr:hover {
  background-color: #f4f7fa; /* Легкий эффект при наведении */
}

.schedule-table div {
  margin-top: 5px; /* Отступ для задач под днем */
}

</style>
