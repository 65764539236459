// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../pages/HomePage.vue';
import store from '@/store'; // Импортируйте ваш Vuex store
import EducationalPlatformPage from '@/pages/EducationalPlatformPage.vue';
import SchedulePage from '@/pages/SchedulePage.vue';
import YourCoursesPage from '@/pages/YourCoursesPage.vue';
import NotFoundPage from '@/pages/NotFoundPage.vue';
import AdminPanelPage from '@/pages/admin/AdminPanelPage.vue';
import SubjectManagerPage from '@/pages/admin/SubjectManagerPage.vue';
const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/edu',
    name: 'EducationalPlatform',
    component: EducationalPlatformPage, // Защищённая страница
    meta: {
      requiresAuth: true, // Добавляем мета-информацию
    },
  },
  {
    path: '/edu/schedule',
    name: 'Schedule',
    component: SchedulePage, // Защищённая страница
    meta: {
      requiresAuth: true, // Добавляем мета-информацию
    },
  },
  {
    path: '/edu/yourCourses',
    name: 'YourCourses',
    component: YourCoursesPage, // Защищённая страница
    meta: {
      requiresAuth: true, // Добавляем мета-информацию
    },
  },
  {
    path: '/admin/panel',
    name: 'AdminPanel',
    component: AdminPanelPage, // Защищённая страница
    meta: {
      requiresAuth: true, // Добавляем мета-информацию
    },
  },
  {
    path: '/admin/subject',
    name: 'SubjectManager',
    component: SubjectManagerPage, // Защищённая страница
    meta: {
      requiresAuth: true, // Добавляем мета-информацию
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFoundPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Мидлвар для проверки токена
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const token = store.state.auth.token;

  if (requiresAuth && !token) {
    next({ name: 'Home' });
  } else if (to.name === 'Home' && token){
    next({name:'EducationalPlatform'});
  }
  else {
    next()
  }
});

export default router;
