import { signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from '@/firebaseConfig';
import Cookies from 'js-cookie';
import apiClient from '@/api/axios';

const authStore = {
  namespaced: true,
  state: {
    user: JSON.parse(Cookies.get('user') || 'null'),
    token: Cookies.get('token') || null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    clearUser(state) {
      state.user = null;
    },
    setToken(state, token) {
      state.token = token;
    },
    clearToken(state) {
      state.token = null;
    },
  },
  actions: {
    async loginWithGoogle({ commit }, router) {
      try {
        const result = await signInWithPopup(auth, googleProvider);
        const token = await result.user.getIdToken();
        commit('setUser', result.user);
        commit('setToken', token);
        // Сохраняем токен и пользователя в куки
        Cookies.set('token', token, { secure: true, sameSite: 'Strict' });
        Cookies.set('user', JSON.stringify(result.user), { secure: true, sameSite: 'Strict' }); // Преобразуем объект пользователя в строку
        if(result.user.displayName && result.user.email && result.user.uid){
        // Запрос к бэкенду для регистрации пользователя
        const userData = {
          name: result.user.displayName, // Имя пользователя
          email: result.user.email,     // Email пользователя
          uid: result.user.uid,                // UID пользователя
        };

        const response = await apiClient.post('/login', userData )

        if (response.status !== 200) {
          throw new Error('Ошибка регистрации пользователя на бэкенде');
        }
      }
        router.push('/edu');
      } catch (error) {
        console.error("Ошибка входа:", error);
        alert("Непредвиденная ошибка");
        Cookies.remove('token');
        commit('clearToken');
        Cookies.remove('user');
        commit('clearUser');
      }
    },
    async updateToken({ commit }) {
      const user = auth.currentUser;
      if (user) {
        const newToken = await user.getIdToken(true);
        Cookies.set('token', newToken, { secure: true, sameSite: 'Strict' });
        commit('setToken', newToken);
      }
    },
    clearToken({ commit }) {
      Cookies.remove('token');
      commit('clearToken');
    },
    clearUser({ commit }) {
      Cookies.remove('user');
      commit('clearUser');
    },
  },
};

export default authStore;
