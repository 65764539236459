<template>
    <nav class="edu-navbar">
      <ul>
        <li><router-link to="/edu">{{ $t('home') }}</router-link></li>
        <li><router-link to="/edu/schedule">{{ $t('schedule') }}</router-link></li>
        <li><router-link to="/edu/announcements">{{ $t('announcements') }}</router-link></li>
        <li><router-link to="/edu/yourCourses">{{ $t('yourCourses') }}</router-link></li>
        <li><router-link to="/edu/gradeBook">{{ $t('gradeBook') }}</router-link></li>
        <li><router-link to="/edu/instructions">{{ $t('instructions') }}</router-link></li>
        <li><router-link to="/edu/yourFiles">{{ $t('yourFiles') }}</router-link></li>
      </ul>
    </nav>
  </template>
  
  <script>
  export default {
    name: "NavBarEdu",
  };
  </script>
  
  <style scoped>
  .edu-navbar {
  background-color: #4a5568; /* Соответствует основным оттенкам проекта */
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.edu-navbar ul {
  display: flex;
  justify-content: space-around;
  list-style: none;
  margin: 0;
  padding: 0;
}

.edu-navbar li {
  display: inline-block;
}

.edu-navbar a {
  color: #f9fafc; /* Цвет текста, чтобы выделяться на темном фоне */
  text-decoration: none;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
}

.edu-navbar a:hover {
  background-color: #2d3748; /* Легкое затемнение при наведении для эффекта взаимодействия */
  border-radius: 5px;
}

  </style>
  