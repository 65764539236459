<template>
  <NavBarEdu />
  <NavBarComponent />
  <div class="courses-dashboard">
    <!-- Левая часть: список курсов -->
    <aside class="courses-list">
      <!--<h2>{{ $t('myCourses') }}</h2>-->
      <ul>
        <li v-for="course in courses" :key="course.id" class="course-item" @click="selectCourse(course)">
          <h3>{{ course.title }}</h3>
          <p>{{ $t('instructor') }}: {{ course.instructor }}</p>
          <p>{{ $t('progress') }}: {{ course.progress }}%</p>
        </li>
      </ul>
    </aside>

    <!-- Правая часть: описание выбранного курса -->
    <section class="course-details" v-if="selectedCourse">
      <h2>{{ selectedCourse.title }}</h2>

      <!-- Список уроков с их деталями и кнопками открытия PDF -->
      <div v-for="lesson in selectedCourse.lessons" :key="lesson.id" class="lesson-details">
        <h4>{{ lesson.title }}</h4>
        <p>{{ lesson.content }}</p>
        <h5>{{ $t('attachedFiles') }}</h5>
        <ul>
          <li v-for="file in lesson.files" :key="file.name">
            <a :href="file.url" target="_blank">{{ file.name }}</a>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>
  
  <script>
  import NavBarComponent from '@/components/NavBarComponent.vue';
import NavBarEdu from '@/components/NavBarEdu.vue';

export default {
  name: "MyCourses",
  components: {
    NavBarComponent,
    NavBarEdu,
  },
  data() {
    return {
      courses: [
        {
          id: 1,
          title: 'Web Development',
          instructor: 'John Doe',
          progress: 70,
          description: 'Learn the basics of web development.',
          duration: '8 weeks',
          lessons: [
            { id: 1, title: 'Lesson 1: Introduction to HTML', content: 'In this lesson, you will learn the basics of HTML.', files: [{ name: 'Lesson 1.pdf', url: '/files/lesson1.pdf' }] },
            { id: 2, title: 'Lesson 2: CSS Fundamentals', content: 'In this lesson, you will learn about CSS.', files: [{ name: 'Lesson 2.pdf', url: '/files/lesson2.pdf' }] },
          ],
        },
        {
          id: 2,
          title: 'Data Science',
          instructor: 'Jane Smith',
          progress: 40,
          description: 'Understand data analysis and machine learning.',
          duration: '10 weeks',
          lessons: [
            { id: 1, title: 'Lesson 1: Introduction to Data Science', content: 'Overview of data science concepts.', files: [{ name: 'Data Science Intro.pdf', url: '/files/ds_intro.pdf' }] },
            { id: 2, title: 'Lesson 2: Machine Learning Basics', content: 'Learn the basics of machine learning.', files: [{ name: 'ML Basics.pdf', url: '/files/ml_basics.pdf' }] },
          ],
        },
        {
          id: 3,
          title: 'Graphic Design',
          instructor: 'Alice Johnson',
          progress: 50,
          description: 'Create stunning visuals and designs.',
          duration: '6 weeks',
          lessons: [
            { id: 1, title: 'Lesson 1: Principles of Design', content: 'Learn the key principles of graphic design.', files: [{ name: 'Design Principles.pdf', url: '/files/design_principles.pdf' }] },
            { id: 2, title: 'Lesson 2: Tools and Techniques', content: 'Explore various tools used in graphic design.', files: [{ name: 'Tools and Techniques.pdf', url: '/files/tools_techniques.pdf' }] },
          ],
        },
      ],
      selectedCourse: null,
    };
  },
  methods: {
    enrollInCourse() {
      // Логика для записи на курс
      alert(`You have enrolled in ${this.selectedCourse.title}`);
    },
    selectCourse(course) {
      this.selectedCourse = course;
    },
  },
};
  </script>
  
  <style scoped>
/* Основной контейнер для панели курсов */
.courses-dashboard {
  display: flex;
  height: calc(100vh - 60px); /* Отнимаем высоту навбара */
  background-color: #f9fafc;
  padding: 20px;
  gap: 20px;
}

/* Левая часть: список курсов */
.courses-list {
  width: 25%;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.courses-list h2 {
  color: #333;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
  margin-bottom: 20px;
}

.courses-list ul {
  list-style-type: none;
  padding-left: 0;
}

.course-item {
  background-color: #f4f7fa;
  border: 1px solid #d9e2ec;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.course-item:hover {
  background-color: #e6ecf3;
  transform: translateY(-4px);
}

.course-item h3 {
  color: #2d3748;
  font-size: 18px;
  margin-bottom: 8px;
}

.course-item p {
  color: #718096;
  font-size: 14px;
  margin: 4px 0;
}

/* Правая часть: описание выбранного курса */
.course-details {
  flex-grow: 1;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.course-details h2 {
  color: #2d3748;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 24px;
  margin-bottom: 20px;
}

.course-details p {
  color: #4a5568;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.lesson-details {
  margin-top: 30px;
  background-color: #f9fafc;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.lesson-details h4 {
  color: #2d3748;
  font-size: 20px;
  margin-bottom: 10px;
}

.lesson-details p {
  color: #4a5568;
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 15px;
}

.lesson-details ul {
  list-style-type: none;
  padding-left: 0;
}

.lesson-details li {
  margin-bottom: 10px;
}

.lesson-details a {
  color: #3182ce;
  text-decoration: none;
  font-weight: 500;
}

.lesson-details a:hover {
  text-decoration: underline;
}

/* Кнопка записи на курс */
button {
  background-color: #3182ce;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 24px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #2b6cb0;
}

/* Общие стили для навбара */
.edu-navbar {
  background-color: #4a5568;
  padding: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.edu-navbar ul {
  display: flex;
  justify-content: space-around;
  list-style: none;
  margin: 0;
  padding: 0;
  color: white;
}

.edu-navbar li {
  font-size: 16px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}


  </style>
  